<template>
  <base-section id="theme-features">
    <base-section-heading :title="translations.offerTitle">
      <div v-html="documentToHtmlString(translations.offerDescription)" />
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in translations.offerFeatures"
          :key="i"
          cols="12"
          md="4"
        >
          <v-tooltip
            v-if="documentToHtmlString(feature.description).length && $vuetify.breakpoint.mdAndUp"
            bottom
            color="#212121"
            :open-on-focus="true"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="pointer"
                v-on="on"
              >
                <base-avatar-card
                  v-bind="feature"
                  align="left"
                  horizontal
                  :icon="feature.icon"
                  :space="0"
                  :clickable="true"
                  hover-color="#e12b31"
                />
              </div>
            </template>
            <span v-html="documentToHtmlString(feature.description)" />
          </v-tooltip>
          <base-avatar-card
            v-else
            v-bind="feature"
            align="left"
            horizontal
            :icon="feature.icon"
            :space="0"
          >
            <div
              v-if="documentToHtmlString(feature.description).length"
              v-html="documentToHtmlString(feature.description)"
            />
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import { STORE_NAMESPACE_OFFER } from '@/views/offer/Index.vue'
  import { mapState } from 'vuex'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

  export default {
    name: 'SectionOfferFeatures',
    computed: {
      ...mapState(STORE_NAMESPACE_OFFER, [
        'translations',
      ]),
    },
    methods: {
      documentToHtmlString,
    },
  }
</script>
