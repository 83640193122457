<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'
  import FetchPage from '@/mixins/fetchPage'
  const OFFER = '66Um64Hq7uZaqneFGTXzih'
  export const STORE_NAMESPACE_OFFER = 'page/offer'
  export default {
    name: 'Offer',

    metaInfo: { title: 'Oferta' },

    extends: View,

    mixins: [
      LoadSections([
        'sub-hero',
        'offer-features',
        'contact-us',
        'trusted-us',
        'footer',
      ]),
      FetchPage(OFFER, STORE_NAMESPACE_OFFER),
    ],

    props: {
      id: {
        type: String,
        default: 'about',
      },
    },
  }
</script>
